<template>
  <div>
    <div class="header">
      <router-link to="/">
        <div class="doll"></div>
        <div class="Logo"></div>
      </router-link>
      <router-link to="/pc/contacts">
        <div class="ContactUs"></div>
      </router-link>
      <div class="QRcode" @mouseover="QRcode_open" @mouseout="QRcode_close"></div>
      <div class="QRcode_main" v-bind:style="QRcode"></div>
      <!--      <div class="ComViews" @mouseover="Views_open" @mouseout="Views_close" v-bind:style="ComViewsImg">-->
      <!--        <div class="open_In" v-bind:style="In_Views">-->
      <!--          <div class="nav_block">-->
      <!--            <div class="block" @click="Up_open">注册</div>-->
      <!--            <div class="block" @click="In_open">登陆</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="open_Up" v-bind:style="Up_Views">-->
      <!--          <div class="nav_block">-->
      <!--            <div class="block" @click="user_open">个人信息</div>-->
      <!--            <div class="block" @click="dropOut_open">退出</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="advertisement" v-bind:style="adver">
      <div class="block" @click="close_adver"></div>
    </div>
    <!--  登陆-->
    <div class="In_main" v-bind:style="main.In">
      <div class="main">
        <div class="black" @click="close"></div>
        <div class="logo"></div>
        <div class="title_m">账户密码登陆</div>
        <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
        <div class="account">
          <div class="title">账户</div>
          <input v-model="form.account">
        </div>
        <div class="password">
          <div class="title">密码</div>
          <input v-model="form.password" type="password" @keydown.enter="In_Get">
        </div>
        <button @click="In_Get">登陆</button>
        <div class="footer">
          <div class="scan_code">扫码登陆</div>
          <div class="in_code" @click="Up_open">立即注册</div>
          <div class="code_c">扫码功能暂未开通</div>
          <!--        <div class="scan_account">账户密码登陆</div>-->
        </div>
      </div>
    </div>
    <!--  注册-->
    <div class="Up_main" v-bind:style="main.Up">
      <div class="main">
        <div class="black" @click="close"></div>
        <div class="logo"></div>
        <div class="title_m">注册</div>
        <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
        <div class="account">
          <div class="title">账户</div>
          <input v-model="form.Account">
        </div>
        <div class="password">
          <div class="title">密码</div>
          <input v-model="form.Password" type="password">
        </div>
        <div class="passwords">
          <div class="title">二级密码</div>
          <input v-model="form.Passwords" type="password">
        </div>
        <div class="email">
          <div class="title">电子邮箱</div>
          <input v-model="form.Mail" type="password">
        </div>
        <div class="name">
          <div class="title">姓名</div>
          <input v-model="form.Name">
        </div>
        <div class="code">
          <div class="title">验证码</div>
          <input v-model="form.Code" type="password">
        </div>
        <div class="code_img" @click="get_code" v-html="codeimg"></div>
        <button @click="Up_Get">注册</button>
        <div class="footer">
          <div class="in_code" @click="In_open">立即登陆</div>
          <!--        <div class="scan_account">账户密码登陆</div>-->
        </div>
      </div>
    </div>
    <!--  个人信息-->
    <div class="user_main" v-bind:style="main.user">
      <div class="main">
        <div class="black" @click="close"></div>
        <div class="logo"></div>
        <div class="title_m">个人信息</div>
        <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
        <div class="img" v-bind:style="form.Imgfile"></div>
        <input type="file" class="img_file" id="file" @change="user_imgUP"
               accept="image/png, image/jpeg, image/gif, image/jpg">
        <div class="name">
          <div class="title">姓名</div>
          <input v-model="form.Name">
        </div>
        <div class="email">
          <div class="title">电子邮箱</div>
          <input v-model="form.Mail" unselectable="on" readonly style="background-color: #e4e7ed;cursor: no-drop">
        </div>
        <div class="age">
          <el-radio-group v-model="form.Age" size="small">
            <el-radio-button label="男"></el-radio-button>
            <el-radio-button label="女"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="category">
          <el-radio-group v-model="form.Category" size="small">
            <el-radio-button label="技术"></el-radio-button>
            <el-radio-button label="艺术"></el-radio-button>
            <el-radio-button label="科学"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="Title">
          <div class="title">职称</div>
          <input v-model="form.Title">
        </div>
        <div class="School">
          <div class="title">研究机构</div>
          <input v-model="form.School">
        </div>
        <div class="Link">
          <div class="title">个人主页</div>
          <input v-model="form.Link">
        </div>
        <div class="Research">
          <div class="title">研究方向</div>
          <input v-model="form.Research">
        </div>
        <button @click="User_Getup">更新</button>
      </div>
    </div>
    <!--  修改密码-->
    <div class="password_main" v-bind:style="main.password">
      <div class="main">
        <div class="logo"></div>
        <div class="black" @click="close"></div>
        <div class="title_m">修改密码</div>
        <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
        <div class="account">
          <div class="title">原密码</div>
          <input v-model="form.Password" type="password" @keydown.enter="In_Get">
        </div>
        <div class="password">
          <div class="title">新密码</div>
          <input v-model="form.Passwords" type="password" @keydown.enter="In_Get">
        </div>
        <button @click="Pass_Getup">提交</button>
      </div>
    </div>
    <!--  退出账户确认框   -->
    <div class="dropOut_main" v-bind:style="main.dropOut">
      <div class="main">
        <div class="logo"></div>
        <div class="title_m" style="font-weight: bold">是否退出当前账号？</div>
        <div class="remind" v-bind:style="remind" v-text="remind_text"></div>
        <button class="confirm" @click="user_exit">确认</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>

</template>

<script>
//提醒失败与成功词语
//background-color: rgba(89,199,93,0.78);
//background-color: rgba(143,10,6,0.78);
//height: 20px;

export default {
  mounted() {
    this.Up_token();
    if (this.$route.path == '/pc/home') {
      this.adver = 'display:none';
    } else {
      setTimeout(() => {
        this.adver = '';
        // this.adver = 'height:220px;'
      }, 300)
    }
  },
  data() {
    return {
      Views_status: false,
      In_Views: '',
      Up_Views: '',
      ComViewsImg: '',
      QRcode: '',
      codeimg: '',
      remind: '',
      remind_text: '',
      main: {
        In: '',
        Up: '',
        user: '',
        password: '',
        dropOut: ''
      },
      adver: '',
      form: {
        Account: '',
        Password: '',
        Passwords: '',
        Category: '',
        Mail: '',
        Name: '',
        Code: '',
        Age: '',
        School: '',
        Link: '',
        SvgData: '',
        Imgfiles: '',
        Imgfile: ''
      }
    }
  },
  methods: {
    Up_token() {
      if (localStorage.token) {
        if (this.$route.path == '/pc/search') {
          this.$parent.Fx_In_Get();
        }
        this.Views_status = true
      } else {
        if (this.$route.path == '/pc/search') {
          this.$parent.Fx_User_Get();
        }
        this.Views_status = false
      }
      this.User_Img()
    },
    user_exit() {
      this.remind_success('退出成功')
      setTimeout(() => {
        localStorage.removeItem('token');
        this.Up_Views = 'display:none';
        this.Up_token();
        this.ComViewsImg = '';
      }, 1400)
    },
    Views_close() {
      if (this.Views_status == false) {
        this.In_Views = 'display:none';
      } else {
        this.Up_Views = 'display:none';
      }
    },
    Views_open() {
      if (this.Views_status == false) {
        this.In_Views = 'display:block';
      } else {
        this.Up_Views = 'display:block';
      }

    },
    Pass_Getup() {
      let _id = localStorage.token;
      if (this.form.Password && this.form.Passwords) {
        if (_id) {
          this.$http.get(process.env.VUE_APP_URL + 'admin/login/change', {
            params: {
              id: _id,
              user_pow: this.form.Password,
              user_newpow: this.form.Passwords
            }
          }).then(str => {
            let sum = str.data.sum;
            if (sum == 'ok') {
              this.remind_success('密码修改成功')
            } else {
              this.remind_failure('原密码错误')
            }
          })
        } else {
          this.remind_failure('服务端异常')
        }
      } else {
        this.remind_failure('原密码与新密码必填')

      }

    },
    User_Getup() {
      let _id = localStorage.token;
      this.$http.post(process.env.VUE_APP_URL + 'admin/user/up', {
        _id: _id,
        data: {
          Name: this.form.Name,
          Age: this.form.Age,
          Category: this.form.Category,
          Link: this.form.Link,
          Mail: this.form.Mail,
          School: this.form.School,
          Title: this.form.Title,
          Research: this.form.Research,
          Imgfile: this.form.Imgfiles,
        }
      }).then(str => {
        if (str.data.sum == 'ok') {
          this.remind_success('更新成功')
          this.User_Img();
        }

      })
    },
    user_imgUP(str) {
      let file = str.target.files[0];
      let formData = new FormData();
      formData.append('file', file);
      this.$http.post(process.env.VUE_APP_URL + 'admin/user/image', formData).then((data) => {
        let sum = data.data;
        if (sum.message == "ok") {
          this.form.Imgfile = 'background-image: url(' + process.env.VUE_APP_URL + 'image/' + sum.Imgfile + ');';
          this.form.Imgfiles = sum.Imgfile;
        } else {
          this.$message({
            showClose: true,
            message: '服务端异常！',
            type: 'warning'
          });
        }
      });
    },
    close_text() {
      setTimeout(() => {
        this.remind_text = ''
        this.form.Account = ''
        this.form.Password = ''
        this.form.Passwords = ''
        this.form.Category = ''
        this.form.Mail = ''
        this.form.Name = ''
        this.form.Code = ''
        this.form.Age = ''
        this.form.School = ''
        this.form.Lin = ''
        this.form.Imgfiles = ''
        this.form.Imgfile = ''
        this.form.account = ''
        this.form.password = ''
      }, 10)
    },
    In_Get() {
      let account = this.form.account;
      let password = this.form.password;
      this.$http.get(process.env.VUE_APP_URL + 'admin/login/land', {
        params: {
          Account: account,
          Paws: password
        }
      }).then(str => {
        if (str.data.sum == '1') {
          let id = str.data.data;
          localStorage.setItem("token", id);
          this.Up_token();
          this.remind_success('登陆成功')
        } else {
          this.remind_failure('登陆失败')
        }
      })
    },
    Up_Get() {
      if (this.form.Name == "" || this.form.Account == "" || this.form.Password == "" || this.form.Passwords == "" || this.form.Mail == "" || this.form.Code == "") {
        this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
        this.remind_text = '所有项必填，请重试';
        setTimeout(() => {
          this.remind = '';
        }, 3000)
        return;
      }

      if (this.form.SvgData == this.form.Code) {
        if (this.form.Password == this.form.Passwords) {
          this.$http.get(process.env.VUE_APP_URL + 'admin/user/add', {
            params: {
              Name: this.form.Name,
              Account: this.form.Account,
              Paws: this.form.Password,
              Email: this.form.Mail
            }
          }).then((res) => {
            let sum = res.data;
            if (sum.sum == "211") {
              this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
              this.remind_text = '账号已注册';
              setTimeout(() => {
                this.remind = '';
              }, 3000)
            } else {
              this.remind = 'height: 20px;background-color: rgba(89,199,93,0.78)'
              this.remind_text = '注册成功';
              setTimeout(() => {
                this.close();
                this.remind = '';
              }, 1200)
            }
          })
        } else {
          this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
          this.remind_text = '密码与二级密码不相同,请重试';
          setTimeout(() => {
            this.remind = '';
          }, 3000)
        }
      } else {
        this.form.Code = ''
        this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
        this.remind_text = '验证码错误';
        setTimeout(() => {
          this.remind = '';
        }, 3000)
      }
    },
    QRcode_open() {
      this.QRcode = 'display:block'
    },
    QRcode_close() {
      this.QRcode = 'display:none';
    },
    User_Img() {
      if (this.Views_status == true) {
        let _id = localStorage.token;
        this.$http.get(process.env.VUE_APP_URL + 'database/find/Precise', {
          params: {
            _id: _id
          }
        }).then(str => {
          let data = str.data.results[0];
          this.ComViewsImg = 'background-image: url(' + process.env.VUE_APP_URL + 'image/' + data.Imgfile + ');';
          this.$parent.FX_Get_Img(data.Imgfile, data.Name);
        })
      }
    },
    user_open() {
      let _id = localStorage.token;
      this.$http.get(process.env.VUE_APP_URL + 'database/find/Precise', {
        params: {
          _id: _id
        }
      }).then(str => {
        let data = str.data.results[0];
        if (data) {
          if (data.Imgfile) {
            this.form.Imgfile = 'background-image: url(' + process.env.VUE_APP_URL + 'image/' + data.Imgfile + ');';
            this.form.Imgfiles = data.Imgfile;
          }
          this.form.Name = data.Name;
          this.form.Age = data.Age;
          this.form.School = data.School;
          this.form.Category = data.Category;
          this.form.Mail = data.Mail;
          this.form.Title = data.Title;
          this.form.Link = data.Link;
          this.form.Research = data.Research
        }
      })

      this.main.user = 'display:block';
    },
    close_adver() {
      this.adver = 'height:0'
    },
    get_code() {
      this.$http.get(process.env.VUE_APP_URL + 'admin/svg/verification').then(str => {
        let img = str.data.svgImg;
        this.codeimg = img;
        this.form.SvgData = str.data.svgData;
      })
    },
    In_open() {
      let path = this.$route.fullPath;
      this.$router.push({
        path: '/pc/account/registered',
        query: {
          goto: path
        }
      })

      // this.close();
      // setTimeout(() => {
      //   this.main.In = 'display:block';
      // }, 2)
    },
    Up_open() {
      let path = this.$route.fullPath;
      this.$router.push({
        path: '/pc/account/signin',
        query: {
          goto: path
        }
      })
      // this.close();
      // setTimeout(() => {
      //   this.main.Up = 'display:block';
      //   this.get_code();
      // }, 2)
    },
    password_open() {
      this.close();
      setTimeout(() => {
        this.main.password = 'display:block';
      }, 2)
    },
    close() {
      setTimeout(() => {
        this.main.In = 'display:none';
        this.main.Up = 'display:none';
        this.main.user = 'display:none';
        this.main.password = 'display:none';
        this.main.dropOut = 'display:none';
        this.close_text();
      }, 1)
    },
    dropOut_open() {
      this.main.dropOut = 'display:block'
    },
    remind_success(text) {
      this.remind = 'height: 20px;background-color: rgba(89,199,93,0.78)'
      this.remind_text = text;
      setTimeout(() => {
        this.remind = ''
        this.close();
      }, 1200)
    },
    remind_failure(text) {
      this.remind = 'height: 20px;background-color: rgba(143,10,6,0.78)'
      this.remind_text = text;
      setTimeout(() => {
        this.remind = ''
        this.remind_text = '';
      }, 2000)
    }


  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 60px;
  position: relative;

  .ComViews {
    position: absolute;
    right: 230px;
    top: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-image: url("../../assets/image/home/avatar.png");
    background-size: 130% 130%;
    background-position: center center;
    background-repeat: no-repeat;
    @mixin open_block {
      position: absolute;
      width: 130px;
      height: 120px;
      left: -45px;
      top: 40px;
      z-index: 2;
      background-image: url("../../assets/image/home/message_1.png");
      background-size: 100% 100%;
      display: none;
      .nav_block {
        position: absolute;
        width: 70px;
        height: 60px;
        left: 30px;
        top: 36px;

        .block {
          height: 30px;
          width: 100%;
          line-height: 30px;
          font-size: 12px;
          text-align: center;
          transition: 0.4s;
          margin-bottom: 5px;
          cursor: pointer;

          &:hover {
            background-color: #29294f;
            border-radius: 7px;
            color: #ffffff;
          }
        }
      }
    }

    .open_In {
      @include open_block;
    }

    .open_Up {
      @include open_block;
    }
  }

  .doll{
    background-image: url("../../assets/image/home/doll.png");
    width: 50px;
    height: 50px;
    background-size: 100% 100%;
    position: absolute;
    left: 50px;
    top: 0;
  }

  .Logo {
    background-image: url("../../assets/image/home/logo.png");
    width: 80px;
    height: 30px;
    background-size: 100% 100%;
    position: absolute;
    left: 100px;
    top: 15px;
  }

  .ContactUs {
    background-image: url("../../assets/image/home/contacts.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% 110%;
    width: 210px;
    height: 60px;
    position: absolute;
    right: 130px;
    top: 0;
    cursor: pointer;
  }

  .QRcode {
    background-image: url("../../assets/image/home/QRcode.png");
    background-size: 100% 100%;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 85px;
    top: 17px;
    cursor: pointer;
  }

  .QRcode_main {
    width: 125px;
    height: 125px;
    position: absolute;
    right: 30px;
    top: 50px;
    z-index: 2;
    display: none;
    background-image: url("../../assets/image/home/QRcode_img.png");
    background-size: 100% 100%;
  }

  @mixin Sign {
    background-color: #203761;
    width: 70px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    border-radius: 6px;
    background-size: 100% 100%;
    position: absolute;
    text-align: center;
    cursor: pointer;
  }

  .Sign_user {
    @include Sign;
    right: 435px;
    top: 15px;
    width: 100px;
    display: none;
  }

}

.advertisement {
  width: 100%;
  margin: 0 auto;
  height: 0;
  background-color: #cacaca;
  position: relative;
  transition: 2s;
  overflow: hidden;
  margin-bottom: 20px;

  .block {
    position: absolute;
    background-image: url("../../assets/image/ico/black.png");
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    right: 40px;
    top: 20px;
    cursor: pointer;
  }
}

@mixin main {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  .logo {
    background-image: url("../../assets/image/home/logo.png");
    width: 80px;
    height: 30px;
    background-size: 100% 100%;
    position: absolute;
    left: 40px;
    top: 30px;
  }
  .black {
    position: absolute;
    background-image: url("../../assets/image/ico/black.png");
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    right: 30px;
    top: 35px;
    cursor: pointer;
  }
  .main {
    width: 400px;
    height: 380px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -175px;
    border-radius: 12px;

    .remind {
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }
  }

  .title_m {
    position: absolute;
    left: 160px;
    top: 30px;
    height: 30px;
    line-height: 30px;

    span {
      width: 20px;
      height: 100%;
      display: inline-block;
      text-align: center;
      color: #cacaca;
    }
  }
  button {
    position: absolute;
    width: 270px;
    height: 40px;
    left: 65px;
    bottom: 80px;
    border: none;
    outline: none;
    background: none;
    background-color: #203761;
    color: #ffffff;
    cursor: pointer;
  }
  .footer {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #e4e7ed;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    .in_code {
      position: absolute;
      right: 30px;
      color: rgba(15, 69, 121, 0.78);
      top: 7px;
      font-size: 14px;
      cursor: pointer;
    }

    .scan_code {
      position: absolute;
      left: 30px;
      color: rgba(15, 69, 121, 0.78);
      top: 7px;
      font-size: 14px;
      cursor: not-allowed;
    }

    .code_c {
      color: darkred;
      position: absolute;
      left: 100px;
      top: 9px;
      font-size: 8px;
    }

    .scan_account {
      position: absolute;
      left: 110px;
      color: rgba(15, 69, 121, 0.78);
      top: 7px;
      font-size: 14px;
    }
  }
}

@mixin input {
  position: absolute;
  width: 300px;
  height: 40px;
  .title {
    position: absolute;
    left: 20px;
    top: 5px;
    font-weight: 300;
  }
  input {
    width: 190px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 0;
    outline: none;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #cacaca;
    transition: 0.5s;

    &:focus {
      border-bottom: 2px solid #203761;
    }
  }
}


.Up_main {
  @include main;
  display: none;
  z-index: 5;

  .main {
    height: 610px;
    margin-top: -350px;

    .remind {
      //background-color: rgba(89,199,93,0.78);
      //background-color: rgba(143,10,6,0.78);
      //height: 20px;
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }

    .account {
      @include input;
      left: 50px;
      top: 120px;
    }

    .password {
      @include input;
      left: 50px;
      top: 180px;
    }

    .passwords {
      @include input;

      .title {
        left: -10px;
      }

      left: 50px;
      top: 240px;
    }

    .email {
      @include input;

      .title {
        left: -10px;
      }

      left: 50px;
      top: 300px;
    }

    .name {
      @include input;
      left: 50px;
      top: 360px;
    }

    .code {
      @include input;
      left: 50px;
      top: 420px;

      .title {
        left: 5px;
      }

      input {
        width: 80px;
        left: 70px;
      }
    }

    .code_img {
      position: absolute;
      width: 105px;
      height: 45px;
      right: 70px;
      top: 404px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }
}

.In_main {
  @include main;
  display: none;
  z-index: 5;

  .main {
    .remind {
      //background-color: rgba(89,199,93,0.78);
      //background-color: rgba(143,10,6,0.78);
      //height: 20px;
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }

    .account {
      @include input;
      left: 50px;
      top: 110px;
    }

    .password {
      @include input;
      left: 50px;
      top: 190px;
    }

  }
}

.user_main {
  @include main;
  z-index: 5;
  display: none;

  .main {
    width: 690px;
    height: 570px;
    margin-left: -325px;
    margin-top: -285px;

    .remind {
      //background-color: rgba(89,199,93,0.78);
      //background-color: rgba(143,10,6,0.78);
      //height: 20px;
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }

    .title_m {
      position: absolute;
      left: 150px;
    }

    .img {
      background-size: 100% 100%;
      background-color: #161f28;
      border-radius: 12px;
      width: 200px;
      height: 290px;
      position: absolute;
      left: 27px;
      top: 120px;
    }

    .img_file {
      width: 200px;
      height: 290px;
      position: absolute;
      left: 27px;
      top: 88px;
      cursor: pointer;
      outline: none;
      opacity: 0;
    }

    .name {
      @include input;

      .title {
        font-size: 14px;
      }

      input {
        width: 80px;
      }

      width: 185px;
      left: 230px;
      top: 140px;
    }

    .email {
      .title {
        font-size: 14px;
      }

      @include input;

      input {
        width: 140px;
      }

      width: 270px;
      left: 230px;
      top: 210px;
    }

    .age {
      @include input;
      width: 90px;
      right: 80px;
      top: 140px;
    }

    .category {
      @include input;
      width: 169px;
      right: 5px;
      top: 210px;
    }

    .Title {
      .title {
        font-size: 14px;
      }

      @include input;

      input {
        width: 50px;
      }

      width: 152px;
      left: 230px;
      top: 270px;
    }

    .School {
      .title {
        font-size: 14px;
      }

      @include input;

      input {
        width: 90px;
      }

      width: 220px;
      left: 430px;
      top: 270px;
    }

    .Link {
      .title {
        font-size: 14px;
      }

      @include input;

      input {
        width: 290px;
      }

      width: 420px;
      left: 230px;
      top: 330px;
    }

    .Research {
      .title {
        font-size: 14px;
      }

      @include input;

      input {
        width: 480px;
      }

      width: 620px;
      left: 30px;
      top: 430px;
    }

    button {
      width: 570px;
      bottom: 30px;
      transition: 0.3s;
      border-radius: 5px;

      &:hover {
        background-color: #e4e7ed;
        color: #29294f;
      }
    }
  }
}

.password_main {
  @include main;
  display: none;
  z-index: 5;

  .main {
    height: 330px;

    .remind {
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      width: 100%;
      height: 0;
      line-height: 20px;
      position: absolute;
      top: 70px;
      transition: 0.5s;
    }

    .account {
      @include input;
      left: 50px;
      top: 120px;

      .title {
        left: 10px;
      }
    }

    .password {
      @include input;
      left: 50px;
      top: 180px;

      .title {
        left: 10px;
      }
    }

    button {
      bottom: 35px;
    }
  }
}

.dropOut_main {
  @include main;
  display: none;
  z-index: 5;

  .main {
    height: 190px;

    .confirm {
      width: 100px;
      left: 80px;
      bottom: 40px;
      border-radius: 4px;
    }

    .cancel {
      width: 100px;
      left: 230px;
      bottom: 40px;
      border-radius: 4px;
    }

    button {
      transition: 0.3s;

      &:hover {
        background-color: #e4e7ed;
        color: #29294f;
      }
    }
  }

}

</style>
